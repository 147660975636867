.label,
.errorText {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 8px;
  display: block;
  color: var(--text-muted-color);
}
.errorText {
  padding-bottom: 0;
  padding-top: 5px;
}
.error {
  color: var(--failed-color);
}
.sm {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}
