.value {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent !important;
  transition: color 0.2s ease;
}

.accent {
  color: var(--accent-color);
}
.primary {
  color: var(--primary-color);
}
.successLight {
  color: var(--success-light-color);
}
.success {
  color: var(--text-success-color);
}
.danger {
  color: var(--danger-color);
}
.warning {
  color: var(--warning-color);
}
