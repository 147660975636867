.item {
  border-radius: 8px;
  background: var(--block-bg-color, #f9fafb);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
}

.itemTop {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.itemStatus {
  display: flex;
  align-items: center;
  gap: 10px;
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.statusTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

.statusDescription {
  color: var(--text-muted-color, #8091b5);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
}

.amount {
  padding-top: 5px;
  color: var(--accent-color, #0052ff);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 114.286% */
}

.bottom {
  border-radius: 10px;
  background: #fff;
  padding: 14px 15px;
}
.requisites {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bank,
.paySystem {
  display: flex;
  align-items: center;
  gap: 6px;
}
.bank {
  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
}
.paySystem {
  svg {
    height: 12px;
    width: 20px;
  }
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
}
.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.block {
  border-radius: 8px;
  background: #fff;
  width: 100%;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.blockTitle {
  color: var(--text-muted-color, #8091b5);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
}
.idContainer {
  padding-right: 67px;
  position: relative;
  width: 100%;
  span {
    display: block;
    text-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
  }
}
.copy {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.receipt {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  a {
    width: 117px;
    color: var(--accent-color, #0052ff);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    white-space: nowrap;
  }
}
.receiptContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  width: calc(100% - 30px - 117px);
  svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.dropContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
}
