.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.content {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.title {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 100% */
}
.subTitle {
  margin-top: 12px;
  color: var(--Text-1, #8091b5);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.variants {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.variant {
  display: flex;
  width: 100%;
  padding: 15px;
  align-items: center;
  gap: 15px;

  border-radius: 8px;
  border: 1px solid var(--Input-Stroke, #f6f6f6);
  background: var(--Input-BG, #fafbfc);
  transition: 0.2s;
  &.active {
    border-radius: 8px;
    border: 1px solid var(--Failed, #fb6c6c);
    background: var(--Input-BG, #fafbfc);
    box-shadow: 0px 4px 10px 0px rgba(251, 108, 108, 0.1);
  }

  p {
    color: var(--Main, #002269);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 150% */
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.notice {
  border-radius: 8px;
  border: 1px solid var(--Light-Stroke, #eef2f9);
  background: var(--Block-BG, #f9fafb);
  padding: 16px 15px;
  display: flex;
  gap: 8px;
  svg {
    flex-shrink: 0;
  }
  p {
    color: var(--Main, #002269);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
}
.submit {
  width: 100%;
  display: inline-flex;
  padding: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Failed-BG, #ffeaea);

  color: var(--Failed, #fb6c6c);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  transition: opacity 0.3s;
  &:disabled {
    opacity: 0.5;
  }
}
