.loader {
  width: 8px;
  height: 8px;
  content: "";
  border-radius: 50%;
  box-shadow: 0 16px 0 var(--accent-color);
  position: relative;
  animation: loader-animation 0.4s ease-in-out alternate infinite;
  animation-delay: 0.32s;
  display: block;
  top: -19px;
  opacity: 0.9;
}

.loader::after,
.loader::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 16px 0 var(--accent-color);
  animation: loader-animation 0.4s ease-in-out alternate infinite;
}

.loader::before {
  left: -12px;
  animation-delay: 0.48s;
}

.loader::after {
  right: -12px;
  animation-delay: 0.16s;
}

@keyframes loader-animation {
  0% {
    box-shadow: 0 16px 0 var(--accent-color);
  }

  100% {
    box-shadow: 0 20px 0 var(--accent-color);
  }
}
