.header {
  height: 64px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-stroke-color, #eef2f9);
  background: var(--New-BG, #f8f9fc);
  flex-shrink: 0;
}
.logo {
  svg {
    height: 27px;
    width: auto;
  }
}
.name {
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
