.paper {
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
  background: var(--block-bg-color, #f9fafb);
}
.bank {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bankLogoContainer {
  width: 36px;
  height: 36px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  img {
    width: 20px;
    height: 20px;
  }
}
.bankLabel {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.reserve {
  margin: 15px 0 0;
  display: flex;
  gap: 10px;
}
.reserveLeft {
  width: calc(100% - 10px - 70px);
  min-height: 70px;
  padding: 15px 20px 15px 20px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--light-stroke-color, #eef2f9);
  background: var(--White, #fff);
  transition: 0.3s;
  &.error {
    border: 1px solid var(--failed-bg-color, #ffeaea);
  }
}
.reserveRight {
  display: flex;
  width: 70px;
  height: 70px;
  padding: 23px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--not-available-bg-color, #ebeef8);
  svg {
    flex-shrink: 0;
  }
  &.accent {
    background: var(--accent-bg-color, #e6eeff);
    svg {
      width: 36px;
      height: 36px;
    }
  }
  &:disabled {
    opacity: 0.5;
  }
}
.reserveTitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  margin-bottom: 12px;
}
.reserveBalance {
  display: flex;
  gap: 10px;
  align-items: center;
}
.reserveCurrency {
  color: var(--Text-2, #c2cde2);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}
.reserveAmount {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  width: calc(100% - 33px - 10px);
  height: 16px;
}
