.wrapper {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
}
.title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--text-muted-color);
  @media (max-width: 480px) {
    display: none;
  }
}
.container {
  position: relative;
}

.button {
  background: var(--block-bg-color);
  border: var(--border);
  border-radius: 12px;
  height: 48px;
  width: 100%;
  max-width: 100%;
  align-items: center;
  text-align: left;
  padding: 0 16px 0 20px;
  transition: 300ms;
  gap: 12px;
  display: grid;
  grid-template-columns: 20px 1fr 10px;
  transition: 300ms;
  &:hover {
    background: var(--accent-bg-color);
    .icon {
      path {
        stroke: var(--accent-color);
      }
    }
  }
  @media (max-width: 480px) {
    padding-left: 15px;
    .selected {
      font-size: 12px;
    }
  }
}

.iconLeft {
  width: 20px;
  height: 20px;
  flex: 20px;
}

.selected {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--accent-color);
}
.placeholder {
  color: var(--text-base-color);
}

.icon {
  width: 10px;
  transform: rotateX(0deg);
  transition: 300ms ease-in-out;
  // width: 12px;
  path {
    transition: 300ms ease-in-out;
    stroke: var(--text-muted-color);
  }
  &.active {
    transform: rotateX(180deg);
    path {
      stroke-opacity: 1;
      stroke: var(--accent-color);
    }
  }
}

.content {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  opacity: 0;
  background: #ffffff;
  border: var(--borderBase);
  box-shadow: var(--shadow);
  border-radius: 12px;
  padding: 20px;
  z-index: 1;
  min-width: 100%;
  width: 480px;
  transition: 300ms ease;
  @media (max-width: 480px) {
    width: 100%;
  }
}
.content.in.active {
  opacity: 1;
}
.fields {
  margin-bottom: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 100%;
}
.fieldsHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.fieldsTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}

.fieldsClose {
  svg {
    height: 24px;
    path {
      transition: 0.3s;
    }
  }
  &:hover {
    path {
      stroke: var(--accent-color);
    }
  }
}
.controls {
  display: grid;
  grid-template-columns: 165px 1fr;
  gap: 10px;
  @media (max-width: 480px) {
    grid-template-columns: 100%;
    button {
      &:last-child {
        grid-row-start: 1;
      }
    }
  }
}

.item {
  display: grid;
  gap: 10px;
  width: 100%;
  grid-template-columns: 100%;
  opacity: 1;
  transition: 0.3s opacity;
  &.hidden {
    opacity: 0.4;
  }
}
.itemHead {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  svg {
    width: 18px;
    height: 18px;
  }
}
