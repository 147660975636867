.grid {
  display: grid;
  gap: 10px;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
}

.datePicker {
  transition: 0.3s;
  height: 40px;
  border-radius: 8px;
  & > * {
    height: 40px;
  }
  & div:first-child {
    padding-right: 20px;
  }
  fieldset {
    border: var(--border);
    overflow: hidden;
    border-radius: 8px;
  }
  input {
    height: 40px;
    padding: 0;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    transition: 0.3s;
    color: var(--text-base-color);
  }
  button {
    padding: 6px;
  }
  svg {
    width: 18px;
    height: 18px;
    color: var(--text-muted-color);
  }
  &:hover {
    fieldset {
      border: var(--border) !important;
    }
  }
  label {
    font-size: 14px;
    line-height: 20px;
    transform: translate(14px, 10px) scale(1);
    &[data-shrink="true"] {
      transform: translate(14px, -8px) scale(0.75);
    }
  }
}
