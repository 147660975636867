.container {
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 10;
  border-top: var(--border);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  flex-shrink: 0;
  a {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
    svg {
      width: 24px;
      height: 24px;
      path {
        transition: stroke 0.3s;
        stroke: var(--text-muted-light-color);
      }
    }
    &.active {
      background-color: var(--accent-color);
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
}
