.section {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sectionTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.paper {
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
  background: var(--block-bg-color, #f9fafb);
}
.userBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userName {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 187.5% */
}
.logOut {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background: var(--failed-bg-color, #ffeaea);
  display: flex;
  justify-content: center;
  align-items: center;
}
