.paper {
  background: var(--block-bg-color);
  border: var(--border);
  border-radius: 16px;
  width: 100%;
}
.whitePaper {
  display: inline-block;
  background: #fff;
  border-radius: 6px;
  padding: 8px 10px;
  box-shadow: 0px 2px 10px 0px rgba(0, 34, 105, 0.05);
}
.page {
  display: grid;
  gap: 40px;
  grid-template-columns: 100%;
  @media (max-width: 480px) {
    gap: 30px;
  }
}
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media (max-width: 480px) {
    grid-template-columns: 100%;
  }
}
.pageBlock {
  display: grid;
  gap: 20px;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
}
.pageList {
  display: grid;
  gap: 10px;
  grid-template-columns: 100%;
  align-content: start;
}

.filters {
  display: grid;
  grid-template-columns: calc(66.66% - 10px) calc(33.33% - 10px);
  width: 100%;
  gap: 20px;
}
.filtersGrid {
  display: grid;
  // grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  grid-template-columns: 100%;
  gap: 10px;
}
.filtersFull {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  width: 100%;
  gap: 20px;
  @media (max-width: 480px) {
    grid-template-columns: 100%;
  }
}

.filtersGridFull {
  display: grid;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  gap: 10px;
  @media (max-width: 480px) {
    grid-template-columns: 100%;
  }
}
@media (max-width: 480px) {
  .filtersTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding-left: 4px;
    button {
      font-weight: 600;
    }
  }
}

.filtersContainer {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  padding-bottom: 20px;
  @media (max-width: 480px) {
    gap: 15px;
  }
}
.filtersResults {
  display: flex;
  gap: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  b,
  button {
    font-weight: 500;
  }
  @media (max-width: 480px) {
    font-weight: 600;
  }
}

.loading {
  background-color: var(--primary-bg-color);
}
.primary {
  background-color: var(--not-available-bg-color);
  color: var(--text-muted-color);
  path {
    stroke: var(--primary-color);
  }
}
.accent {
  background-color: var(--accent-bg-color);
  color: var(--accent-color);
  path {
    stroke: var(--accent-color);
  }
}
.successLight {
  background-color: var(--success-light-transparent-color);
  color: var(--success-light-color);
  path {
    stroke: var(--success-light-color);
  }
}
.success {
  background-color: var(--success-bg-color);
  color: var(--success-color);
  path {
    stroke: var(--success-color);
  }
}
.danger {
  background-color: var(--failed-bg-color);
  color: var(--failed-color);
  path {
    stroke: var(--failed-color);
  }
}
.warning {
  background-color: var(--processing-bg-color);
  color: var(--processing-color);
  path {
    stroke: var(--processing-color);
  }
}
.muted {
  background-color: var(--primary-transparent-color);
  color: var(--text-muted-color);
  path {
    stroke: var(--text-muted-color);
  }
}
.mutedLight {
  background-color: var(--primary-transparent-color);
  color: var(--text-muted-light-color);
  path {
    stroke: var(--text-muted-light-color);
  }
}
.placeHolder {
  color: var(--text-muted-light-color);
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.loading {
  background-color: var(--loading-color);
}
.observer {
  width: 100%;
  height: 50;
  text-align: center;
  padding-top: 15px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.cardWithLink {
  transition: 0.3s box-shadow;
  &:hover {
    box-shadow: var(--shadow-sm);
  }
}

.titleRightGrid {
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 480px) {
    display: none;
  }
  &.mobile {
    display: none;
    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}

.desktop {
  display: block;
  width: 100%;
  @media (max-width: 480px) {
    display: none;
  }
}
.mobile {
  display: none;
  width: 100%;
  @media (max-width: 480px) {
    display: block;
  }
}
