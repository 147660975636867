.container {
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-muted-color);
  display: flex;
  align-items: center;
  gap: 10px;
}
.chevron {
  width: 10px;
  height: 10px;
}
.body {
  .bodyContainer {
    padding-top: 20px;
  }
}
