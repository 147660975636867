.tag {
  border-radius: 8px;
  padding: 10px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  background-color: var(--block-bg-color);
  font-weight: 500;
  font-size: 14px;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
  path {
    transition: 0.3s;
    stroke: var(--text-muted-color);
  }
  button {
    &:hover {
      path {
        stroke: var(--accent-color);
      }
    }
  }
  @media (max-width: 480px) {
    justify-content: space-between;
    font-size: 12px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
