.button {
  height: var(--controls-height);
  border-radius: 8px;
  border: none;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.sm {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    border-radius: 8px;
    height: var(--controls-height);
  }
  &.lg {
    height: var(--controls-height-lg);
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    @media (max-width: 480px) {
      border-radius: 8px;
    }
  }
  &.muted {
    background-color: var(--primary-transparent-color);
    color: var(--text-muted-color);
    path {
      stroke: var(--text-muted-color);
    }
  }
  transition: 0.2s box-shadow;
  &:hover {
    box-shadow: var(--shadow-sm);
  }
  &.loading {
    pointer-events: none;
  }
}

.disabled {
  opacity: 0.5;
}
