.container {
  background: linear-gradient(180deg, #ecf2ff 0%, var(--block-bg-color) 100%);
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  @media (max-width: 480px) {
    padding: 15px;
  }
}
.logo {
  margin-bottom: 42px;
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
  }
  svg {
    width: 160px;
    height: 36px;
  }
}
.formContainer {
  text-align: center;
  padding: 60px 40px;
  background: #ffffff;
  border-radius: 32px;
  width: 540px;
  max-width: 100%;
  padding: 40px;
  @media (max-width: 480px) {
    padding: 60px 20px;
  }
}
.title {
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.description {
  margin-top: 10px;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--text-muted-color);
}
.submit {
  button {
    border-radius: 16px;
    height: 48px;
    @media (max-width: 480px) {
      border-radius: 8px;
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align-last: left;
}
