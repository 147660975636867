.container {
  background-color: #fff;
  max-width: 900px;
  margin: 0 auto;
  height: 100%;
  min-height: calc(100vh);
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 0 40px;
  height: 100vh;
  padding-bottom: 80px;
  @media (max-width: 480px) {
    padding: 0;
    padding-bottom: 80px;
  }
}

.content {
  border-right: var(--border);
  border-left: var(--border);
  padding: 35px 30px;
  width: calc(100%);
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-gutter: stable;
  flex-grow: 1;

  @media (max-width: 480px) {
    width: 100%;
    border: none;
    padding: 0;
  }
}
.bodyContainer {
  flex-grow: 1;
  @media (max-width: 480px) {
    padding: 30px 15px;
  }
}
