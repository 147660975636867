.headContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
}
.togglerBox {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-muted-color);
  white-space: nowrap;
}

.inputContainer {
  padding: 0 20px;
  input {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
.grid {
  display: grid;
  gap: 10px;
  grid-template-columns: calc(50% - 5px) calc(50% - 5px);
}
