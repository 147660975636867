:root {
  font-family: "Inter", sans-serif;
  // colors
  --loading-color: #f7f7f7;
  --accent-color: #0052ff;
  --accent-bg-color: #e6eeff;
  --processing-color: #fec065;
  --processing-bg-color: #fef3e3;
  --success-color: #7ed06a;
  --success-bg-color: #e9fce6;
  --failed-color: #fb6c6c;
  --failed-bg-color: #ffeaea;
  --bg-color: #f3f4f8;
  --text-base-color: #002269;
  --text-muted-color: #8091b5;
  --text-muted-light-color: #c2cde2;
  --light-stroke-color: #eef2f9;
  --block-bg-color: #fbfcfe;
  --not-available-bg-color: #ebeef8;
  --hover-bg-color: #fafbff;
  --input-bg-color: #fafbfc;
  --input-stroke-color: #f6f6f6;
  --card-gradient: linear-gradient(234.64deg, #0052ff 29.61%, #20386b 84.78%);
  --accent-gradient: linear-gradient(180deg, #ecf2ff 0%, #fbfcfe 100%);
  --primary-gradient: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
  --danger-gradient: linear-gradient(180deg, #fff1f1 0%, #ffffff 100%);
  --processing-gradient: linear-gradient(
    180deg,
    #ffffff 0%,
    #fff8ed 0.01%,
    #fffcf8 100%
  );
  // helpers
  --border: 1px solid var(--light-stroke-color);
  --shadow: 0px 10px 40px rgba(18, 62, 112, 0.1);
  --shadow-sm: 0px 4px 10px rgba(18, 62, 112, 0.05);
  color: var(--text-base-color);
  // sizes
  --controls-height: 40px;
  --controls-height-lg: 60px;
}
html {
  overflow: auto;
  scrollbar-gutter: stable;
}

//Hide scroll on modal show styles

.hideScroll {
  overflow: hidden;
}

.MuiTooltip-tooltipPlacementBottom {
  margin-top: 12px !important;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
