.container {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 6px;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--accent-bg-color, #e6eeff);

  svg {
    width: 18px;
    height: 18px;
    path {
      stroke: var(--accent-color);
    }
  }
}
