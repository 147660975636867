.wrapper {
  position: relative;
}
.title {
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  @media (max-width: 480px) {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
  }
}
.rightElement {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
