.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.titleDescription {
  margin-top: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 185.714% */
}
