.uploader {
  position: relative;
  width: 100%;
  height: 120px;
  padding: 14px 30px;
  background: var(--Block-BG, #f9fafb);
  border: var(--border);
  border-radius: 12px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  &:hover,
  &.active {
    // background-color: #eaf3fe;
    // border-color: var(--accent-color);

    .uploaderText {
      color: var(--accent-color);
      path {
        stroke: var(--accent-color);
      }
    }
  }
  &.muted {
    // background-color: var(--accent-bg-color);
    // border: 1px solid var(--accent-bg-color);
    &:hover {
      // background-color: var(--accent-bg-color);
      border-color: var(--accent-bg-color);
    }
    &.active {
      background-color: #eaf3fe;
      border-color: var(--accent-color);
    }
  }
}
.inputFileContainer {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  .plusIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    path {
      transition: 0.3s ease-in;
      stroke: var(--Text-2, #c2cde2);
    }
  }
  &:hover {
    .plusIcon {
      path {
        stroke: var(--accent-color);
      }
    }
  }
}
.inputFile {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  cursor: pointer;
  transition: 0.3s;
  &[type="file"]::file-selector-button {
    visibility: hidden;
    color: transparent;
  }

  &[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    color: transparent;
  }

  &.plus {
    background-color: var(--accent-bg-color);
    position: relative;
    opacity: 1;
    display: block;
  }
}
.error {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: var(--failed-color);
  margin-bottom: 15px;
}

.uploaderContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploaderText {
  width: 100%;
  transition: color 0.3s;
  color: var(--Text-2, #c2cde2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  path {
    transition: stroke 0.3s;
    stroke: var(--Text-2, #c2cde2);
  }
}
