.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  padding-left: 20px;
  height: var(--controls-height-lg);
  transition: 300ms;

  &.white {
    background-color: #fff;
  }
  &:hover {
    background-color: var(--accent-bg-color);
  }
  &.withoutTitle {
    padding-left: 0px;
    grid-template-columns: 100%;
    .button {
      padding-left: 20px;
    }
  }
  &.sm {
    height: 28px;
    border-radius: 6px;
    padding-left: 12px;
    .button {
      padding-left: 5px;
      padding-right: 12px;
    }
    .title,
    .selectedItem {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
    }
  }
  &.md {
    height: var(--controls-height);
    border-radius: 8px;
  }
  &.base {
    height: 48px;
    border-radius: 12px;
  }

  @media (max-width: 480px) {
    padding-left: 15px;
    &.withoutTitle {
      .button {
        padding-left: 15px;
      }
    }
  }
}
.title {
  align-self: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--text-muted-color);
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.button {
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: calc(100% - 20px) 10px;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-left: 10px;
  &:hover {
    .chevron {
      path {
        stroke: var(--accent-color);
      }
    }
  }
  @media (max-width: 480px) {
    gap: 8px;
  }
}
.icon {
  svg {
    width: 20px;
    height: 20px;
  }
}
.selectedItem {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--accent-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 12px;
  &.baseSelected {
    color: var(--text-base-color);
    font-size: 14px;
    line-height: 16px;
  }
  @media (max-width: 480px) {
    &.baseSelected {
      font-size: 12px;
    }
  }
}
.selectedText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.placeholderSelected {
  color: var(--text-muted-color);
}
.chevron {
  transition: 0.3s ease-in;
  transform: rotateX(0deg);
  width: 10px;
  flex-shrink: 0;
  path {
    transition: 0.3s;
  }

  &.active {
    transform: rotateX(180deg);
    path {
      stroke-opacity: 1;
      stroke: var(--accent-color);
    }
  }
}

.dropDown {
  transition: max-height 0.3s;
  overflow: auto;
  visibility: hidden;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 2;
  width: 100%;
  min-width: 160px;
  max-height: 0px;
  padding: 10px 0;
  background: var(--block-bg-color);
  box-shadow: var(--shadow);
  border-radius: 8px;
  transition: visibility 0s ease-in 150ms, max-height 300ms;
  &.visible {
    visibility: visible;
    transition: visibility 0s ease-in 0s, max-height 300ms;
    max-height: 150px;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 3px; /* width of the entire scrollbar */
      display: block;
    }
  }
  &::-webkit-scrollbar {
    width: 3px; /* width of the entire scrollbar */
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 20px;
  }
}
.item {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 16px;
  color: var(--text-muted-color);
  font-weight: 500;
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* Chrome */
  word-wrap: break-word;
  cursor: pointer;
  &.active {
    color: var(--accent-color);
  }
  &:hover {
    background-color: var(--light-stroke-color);
  }
}
