.container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 100%;
  }
}
