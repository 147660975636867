.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.avatar {
  width: 48px;
  height: 48px;
  background-color: var(--accent-bg-color);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.close {
  background-color: var(--failed-bg-color);
  border: 3px solid var(--Block-BG, #f9fafb);
  border-radius: 50%;
  position: absolute;
  right: -14px;
  top: -14px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
  }
  path {
    stroke-width: 2;
  }
}
.danger {
  background-color: var(--failed-bg-color);
}
.info {
  text-align: center;
}
.name {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: var(--text-base-color);
}
.otherInfo {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: var(--text-muted-color);
}
