.container {
  background-color: var(--input-bg-color);
  border: var(--border);
  border-radius: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  transition: 300ms;
  min-width: 120px;

  transition: 0.3s;
  position: relative;
  @media (max-width: 480px) {
    border-radius: 8px;
  }
}

.sm {
  height: 40px;
  border-radius: 8px;
  .input {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
//modes
.light {
  background-color: #fff;
}
.search {
  height: 100%;
  background-color: var(--block-bg-color);
  border-radius: 16px;
  border: var(--border);
  background-color: var(--block-bg-color);
  border-radius: 16px;
}

.empty {
  opacity: 0.5;
}
.active {
  opacity: 1;
  border-color: var(--accent-color);
  .input {
    color: var(--accent-color);
  }
  .leftElement,
  .rightElement {
    color: var(--accent-color);
    path {
      stroke: var(--accent-color);
    }
  }
}

.borderMd {
  border-radius: 8px;
}

.input {
  background-color: transparent;
  color: var(--text-base-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  flex: 1;
  width: 100%;
  font-family: "Inter", sans-serif;
  outline: none;
}
.input::placeholder {
  color: #c1ccda;
  font-weight: 500;
}

.error {
  border: 1px solid rgba(250, 108, 108, 0.5);
  .input {
    color: var(--failed-color);
  }
  .input::placeholder {
    color: var(--failed-color);
    opacity: 0.5;
  }
  .leftElement,
  .rightElement {
    color: var(--failed-color);
    path {
      stroke: var(--failed-color);
      stroke-opacity: 0.75;
    }
  }
}
.leftElement {
  margin-right: 14px;
  path {
    transition: 0.3s;
  }
}
.rightElement {
  transition: 0.3s color ease;
  margin-left: 14px;
  color: rgba(137, 159, 184, 0.5);
  &.date {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 14px;
    pointer-events: none;
  }
}
.container {
  input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 0;
  }
}
