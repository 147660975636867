.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.title {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}
.subTitle {
  color: var(--Text-1, #8091b5);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.requisites,
.id {
  border-radius: 8px;
  background: var(--Block-BG, #f9fafb);
  display: inline-flex;
  padding: 13px 20px;
  align-items: center;
  gap: 78px;
}
.requisites {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.id {
  position: relative;
  padding-right: 98px;
  color: var(--Text-1, #8091b5);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.copy {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}
.footerCancel {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}
.reserve {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  svg {
    width: 18px;
    height: 18px;
  }
}
