.container {
  background-color: var(--accent-transparent-color);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 10px;
  position: relative;
  svg {
    width: 24px;
    height: 24px;
  }
}

// types
.loading {
  background-color: var(--loading-color);
  &.bgDark {
    background-color: #ebebeb;
  }
  path {
    stroke: var(--primary-bg-color);
  }
}
.primary {
  background-color: var(--not-available-bg-color);
  color: var(--text-muted-color);
  &.bgDark {
    background-color: var(--primary-half-transparent-color);
  }
  path {
    stroke: var(--text-muted-color);
  }
}

.accent {
  background-color: var(--accent-bg-color);
  color: var(--accent-color);
  &.bgDark {
    background-color: var(--accent-half-transparent-color);
  }
  path {
    stroke: var(--accent-color);
  }
}

.successLight {
  background-color: var(--success-bg-color);
  color: var(--success-color);
  &.bgDark {
    background-color: var(--success-light-half-transparent-color);
  }
  path {
    stroke: var(--success-color);
  }
}
.success {
  background-color: var(--success-bg-color);
  color: var(--success-color);
  &.bgDark {
    background-color: var(--success-half-transparent-color);
  }
  path {
    stroke: var(--success-color);
  }
}
.danger {
  background-color: var(--failed-bg-color);
  color: var(--failed-color);
  &.bgDark {
    background-color: var(--danger-half-transparent-color);
  }
  path {
    stroke: var(--failed-color);
  }
}
.warning {
  background-color: var(--processing-bg + -color);
  color: var(--processing-color);
  &.bgDark {
    background-color: var(--warning-half-transparent-color);
  }
  path {
    stroke: var(--processing-color);
  }
}

//mode
.square {
  border-radius: 16px;
}

//sizes
.xs {
  width: 16px;
  height: 16px;
  font-size: 10px;
  font-weight: 600;
  & > svg {
    height: 10px;
  }
}
.sm {
  width: 36px;
  height: 36px;
  font-size: 16px;
  font-weight: 600;
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.md {
  .badge {
    top: 0;
    right: 0;
  }
}

.lg {
  width: 60px;
  height: 60px;
  & > svg {
    width: 36px;
    height: 36px;
  }
  &.square {
    border-radius: 24px;
    &Outline {
      border: 4px solid #fff;
    }
  }
}
.xl {
  width: 64px;
  height: 64px;
  & > svg {
    width: 40px;
    height: 40px;
  }
}
