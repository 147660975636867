.container {
  border-radius: 8px;
  border: 1px solid var(--Light-Stroke, #eef2f9);
  background: var(--Block-BG, #f9fafb);
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}
.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
}
.grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, calc(50% - 7.5px));
}
.item {
  display: flex;
  gap: 15px;
  align-items: center;
}
.logo {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  img {
    width: 20px;
    height: 20px;
  }
}
.info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
}
.amount {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
}
.bank {
  color: var(--Text-1, #8091b5);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 100% */
}
.show {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Accent, #0052ff);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 100% */
  svg {
    transition: 0.3s;
    width: 12px;
    height: 12px;
    path {
      stroke: var(--Accent, #0052ff);
      // stroke-width: 1;
    }
  }
  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
}
