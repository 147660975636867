.tokenSwitchContainer {
  display: flex;
  align-items: center;
  //   gap: 12px;
}
.tokenSwitchText {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.tokenState {
  margin: 20px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
}
.tokenDescription {
  color: var(--text-muted-color, #8091b5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}
