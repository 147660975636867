.card {
  display: flex;
  width: 100%;
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Light-Stroke, #eef2f9);
  background: var(--block-bg-color, #f9fafb);
}
.cardTitle {
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 100% */
}
.cardDescription {
  color: var(--text-muted-color, #8091b5);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 150% */
}

.links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  width: 100%;
  height: 60px;
  padding: 15px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--block-bg-color, #f9fafb);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  &:hover {
    .linkRightIcon {
      path {
        stroke: var(--accent-color);
      }
    }
  }
}
.linkLeftIcon,
.linkRightIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  path {
    transition: 0.3s;
  }
}
.linkLeftIcon {
  path {
    stroke: var(--accent-color);
  }
}
.linkText {
  flex-grow: 1;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
