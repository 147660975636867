.amount {
  display: flex;
  align-items: center;
  gap: 12px;
}

.amountValue {
  display: inline-flex;
  height: 48px;
  min-width: 140px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--accent-bg-color, #e6eeff);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  color: var(--accent-color, #0052ff);
}
.amountText {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  strong {
    font-weight: 600;
  }
}
.paper {
  border-radius: 8px;
  border: 1px solid var(--light-stroke-color, #eef2f9);
  background: var(--block-bg-color, #f9fafb);
}
.requisite {
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bank {
  display: flex;
  align-items: center;
  gap: 15px;
}
.bankLogo {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  img {
    width: 36px;
    height: 36px;
  }
}
.bankName {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.bankDescription {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.copy {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.cardNumber,
.uuid {
  height: 48px;
  padding: 0 15px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.cardNumber {
  color: var(--accent-color, #0052ff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.payment {
  margin-right: 16px;
  svg {
    width: 27px;
    height: 16px;
  }
}
.uuid {
  color: var(--text-muted-color, #8091b5);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-right: 77px;
  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.cancel {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 15px;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: var(--failed-color);
      stroke-width: 2;
    }
  }
}

.fileDescription {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.submit {
  display: inline-flex;
  padding: 18px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: var(--accent-bg-color, #e6eeff);
  color: var(--accent-color);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
