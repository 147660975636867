.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: auto;
  display: grid;
  align-items: center;
  padding: 60px 0;
  @media (max-width: 480px) {
    padding: 0;
    overflow: auto;
    height: 100%;
  }
}
.contentModal {
  position: static;
  width: 480px;
  margin: 0 auto;
  left: 0;
  top: 0;
  border: 0;
  padding: 0;
  border-radius: 32px;
  overflow: visible;
  transition: width 0.3s;
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: auto;
  }
}

.content {
  background-color: #fff;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    border-radius: 0;
    overflow: scroll;
    min-height: 100%;
    width: 100%;
    padding: 15px;
    padding-bottom: 30px;
  }
}
.mobileHead {
  display: none;
  @media (max-width: 480px) {
    display: flex;
  }
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .back,
  .share {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: var(--accent-color);
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .share {
    gap: 8px;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
